import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/zNrhdsBbjoc">
    <SEO title="Because He Lives - Easter at Cross Church" />
  </Layout>
)

export default SermonPost
